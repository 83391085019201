.about-container {
  padding: 1em 2em 1em 2em;
}
h2 {
  color: #ffffff;
}
p {
  color: #ffffff;
}

@media (max-width: 768px) {
  .about-container {
    padding: 4em 1em 1em 1em;
  }
}
