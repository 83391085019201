.resume-pdf {
  position: fixed;
  left: calc(20vw + 50px);
  width: 80vw;
}

.pdf-alt {
  top: 60px;
  position: relative;
  color: black;
  margin: 16px;
}

@media (max-width: 768px) {
  .resume-pdf {
    left: 0%;
    margin-left: 0px !important;
    width: 60vw;
  }
}
