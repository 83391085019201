.styled-burger-menu {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  text-align: left;
  background: #242424;
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 769px) {
  .burger-menu {
    display: none;
  }
}

.styled-burger-menu a {
  font-size: 2rem;
  text-transform: uppercase;
  padding: 2rem 0;
  font-weight: bold;
  color: white;
  letter-spacing: 0.5rem;
  text-decoration: none;
  transition: color 0.3s linear;
}
@media (max-width: 768px) {
  .styled-burger-menu {
    font-size: 1.5rem;
    text-align: center;
  }
}

.burger-menu-icon {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.burger-menu-icon div {
  width: 2rem;
  height: 0.25rem;
  background: white;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}
