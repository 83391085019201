.nav-list {
  margin-top: 30px;
  padding-left: 0 !important;
  text-align: center;
}
@media (max-width: 768px) {
  .nav-list {
    margin-bottom: 4em;
  }
}

.nav-item {
  margin-top: 15px;
  display: inline-grid;
  width: 70%;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  background: hsla(0, 0%, 100%, 0.2);
}

.social-link {
  text-decoration: none;
  margin: auto;
}

li {
  transition: background 250ms ease-in-out, transform 150ms ease;
}

li:hover,
li:focus {
  background: hsla(0, 0%, 100%, 0.1) !important;
}
