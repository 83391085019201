.projects-container {
  padding: 0em 2em 1em 2em;
}
@media (max-width: 768px) {
  .projects-container {
    padding: 0em 1em 1em 1em;
  }
}
.projects-container h3 {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}
.projects-container p {
  margin-top: 10px !important;
  margin-bottom: 80px !important;
}
h2,
h3 {
  color: #ffffff;
}
.projects-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 768px) {
  .projects-list {
    grid-template-columns: 2fr;
  }
}
