.certificates {
  padding: 1em 2em 1em 2em;
}

.cert-name {
  font-weight: bold;
}

.cert-contianer {
  display: flex;
}

.cert-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cert-text-container p {
  margin: 5px;
}


h2 {
  color: #ffffff;
}

p {
  color: #ffffff;
}

@media (max-width: 768px) {
  .certificates {
    padding: 4em 1em 1em 1em;
  }
}
