.sidebar-container {
  background: #151515;
  width: 20vw;
  position: fixed;
  height: 100%;
  padding: 15px 25px 15px 25px;
}
@media (max-width: 768px) {
  .sidebar-container {
    display: none;
  }
}
