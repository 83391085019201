.portfolio-container {
  background: #242424;
  margin-left: calc(20vw + 50px);
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .portfolio-container {
    margin-left: 0px !important;
  }
}
