.project {
  position: relative;
  margin: 10px 10px 10px 0px;
  flex: 1 0 30%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  object-fit: cover;
}
.project-image {
  border-radius: 10px;
  width: 100%;
}
.project-info {
  margin: 10px;
}
.project-button-list {
  display: block;
  position: absolute;
  left: 10px;
  bottom: 0;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 10px;
  width: 100%;
}
.project-button-icon {
  margin-right: 10px;
}
button {
  border-radius: 5px;
  background-color: #151515;
  height: 40px;
  width: 150px;
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
}
.button-github {
  float: right;
  margin-right: 20px;
  background: hsla(0, 0%, 100%, 0.2) !important;
}
.button-github:hover,
.button-github:focus {
  background: hsla(0, 0%, 100%, 0.1) !important;
}
button:hover,
button:focus {
  background: #0053ba;
}
button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}

@media (min-width: 769px) and (max-width: 1000px) {
  button {
    width: 120px;
    height: 35px;
    font-size: 0.9rem;
  }
}
