.contact-container {
  padding: 0em 2em 1em 2em;
}
.contact-container p {
  margin-bottom: 0px !important;
}
input[type="email"],
input[type="text"],
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background: hsla(0, 0%, 100%, 0.2);
  border: none;
  padding: 10px;
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  color: hsla(0, 0%, 100%, 0.25);
}
.contact-textarea {
  width: 95% !important;
  height: 90px;
}
.email-input {
  width: 95% !important;
  height: 16px;
}
.name-input {
  width: 95% !important;
  height: 16px;
}
.contact-submit {
  background: #e43030;
}
.contact-submit:hover,
.contact-submit:focus {
  background: #da1111;
}
@media (max-width: 768px) {
  .contact-container {
    padding: 0em 1em 2em 1em;
  }
}
